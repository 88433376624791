

.form__img-input-container {
    position: relative;
    width: '100%';
    height: '100%';
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

/* this class is used to hide the file input */
.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

/* Adding the focus and focus-within styles for accessibility */
/* input.visually-hidden:focus + label,
input.visually-hidden:focus-within + label {
    outline: 8px solid rgba(86, 206, 239, .4);
} */

/* The file input is a labeled control, and since we hid it, 
we're going to use its associated label to trigger it */
.form-img__file-label {
    position: absolute;
    /* top: 0;
    left: 0; */
    display: block;
    width: 80px;
    height: 80px;
    /* border-radius: 50%; */
    cursor: pointer;
    /* background-color: rgba(245, 245, 231, .3); */
}

.form-img__file-label:hover {
    background-color: rgba(245, 245, 231, .5);
}

.form-img__file-label > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .7;
    transform: translate(-50%, -50%);
}

.form-img__file-label:hover > svg {
    opacity: 1;
}

/* The image element is going to be positioned under the 
label element, since the label is a see through, we're going 
to be able to see the preview of the image. */
.form-img__img-preview {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: contain;
    /* border-radius: 50%; */
    /* border: 16px rgb(80, 199, 235) solid; */
}
