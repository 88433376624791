.background-gradient{
    background-image: linear-gradient(270deg, rgba(25, 136, 247, 1) 0%, rgba(247, 25, 136, 1) 100%);
    height: 300px;
    border-radius: 20px;
    margin: 10px auto;
    padding: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}

.backgroundImage{
    background-color: #61dafb;
    background-image: url('https://cdn.shopify.com/s/files/1/0555/3725/files/louis-vuitton-logo_1024x1024.jpg?v=1525874771');
    background-repeat: no-repeat;
    background-size: cover;
  }
  