.resSidebar {
    background-color: #343333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-left: 25px;
    /* width: 300px; */
  }

   /* Sidebar links */
 .resSidebar a {
    display: flex;
    color: #b0b0b0;
    padding: 8px;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    margin: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
  }


  
  /* Active/current link */
  .resSidebar a.active {
    background-color: #111112;
    color: rgb(255, 255, 255);
    border-radius: 16px;
    /* margin: 16px; */
  
  }
  .resSidebar a.active i {
    color: yellow;
  }
  /* .sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
   */
  /* Links on mouse-over */
  .resSidebar a:hover:not(.active) {
    background-color: #000000;
    color: rgb(255, 255, 255);
    border-radius: 16px;
    /* margin: 16px; */
  }
  .resSidebar a:hover:not(.active) i {
    /* background-color: #000000; */
    color: yellow;
    /* border-radius: 16px; */
    /* margin: 16px; */
  }

  /* Style for the custom scrollbar */
  ::-webkit-scrollbar {
    width: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #343333;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

