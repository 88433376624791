


.market {
    background-color: white;
    min-width: 100%;
    width: 100%;
    display: flex;
}

.mainDisplay {
  background-color: #EDF1F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  /* flex-grow: 1; */
  flex:1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  margin-left: 20px;
  /* margin-left: 200px; */
  /* margin-top: 80px; */
}



 .sidebar {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 200px;
    position: fixed;
    /* padding-top: 80px; */
    /* overflow: auto; */
  }
  
   /* Sidebar links */
   .sidebar a {
    display: flex;
    color: black;
    padding: 6px;
    padding-left: 18px;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    margin: 16px;
    width: 90%;
  }
  
  /* Active/current link */
  .sidebar a.active {
    background-color: #E3DAFF;
    color: black;
    border-radius: 32px;
    margin: 16px;
  
  }

  .sidebar a:hover:not(.active) {
    background-color: #E3DAFF;
    color: black;
    border-radius: 32px;
    margin: 16px;
  }

  .container {
    /* width: 90%; */
    background-color: rgb(255, 255, 255);
    padding: 1rem 1rem;
    border-radius: 1rem;
    min-height: 100vh;
    width: 100vw;
}

.background {
  /* width: 95%; */
  /* padding: 50px 50px; */
  /* border-radius: 1rem; */
  /* background-color: red; */
  width: 100%;

}